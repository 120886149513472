import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import Loader from "./loader";
import Img1 from "../assets/images/logo.png";
import { Helmet } from "react-helmet";

const DineInMenuPage = () => {
  const [menuData, setMenuData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbyEFQGi2DSfz85DeiphmDQJJ2VyjMlNWRK9DSbRYNoDBQk6boUaicu186sBZ1aEPvvx/exec?action=getMenuData"
      );

      const data = await response.json();
      //   const groupedData = data.reduce((result, item) => {
      //     if (!result[item.group]) {

      //         result[item.group] = [];
      //       } result[item.group].push(item);
      //       return result;
      //   }, {});
      const groupedData = [];

      data.forEach((item) => {
        let idx = groupedData.findIndex((oo) => oo.group_name === item.group);
        if (idx === -1) {
          groupedData.push({ group_name: item.group, itemArray: [item] });
        } else {
          groupedData[idx].itemArray.push(item);
        }
        // if (groupedData[item.group]) {
        //   groupedData[item.group].push(item);
        // } else {
        //   groupedData[item.group] = [item];
        // }
      });
      console.log(groupedData, "data");
      setMenuData(groupedData);
    };

    fetchData();
    // console.log(menuData)
  }, []);

  useEffect(() => {
    setTimeout(() => {
      try {
        const element = document.getElementById("full-page-loader");
        if (element) element.parentNode.removeChild(element);
      } catch (error) {
        console.log(error);
      }
    }, 2000);
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap"
          rel="stylesheet"
        ></link>
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#144a95"
          media="(prefers-color-scheme: dark)"
        />
        <meta name="theme-color" content="#144a95" />
      </Helmet>
      <div id="full-page-loader">
        <Loader />
      </div>
      <div
        className="max-w-lg mx-auto bg-secondary-lightblue-300 h-full pb-4"
        id="player"
      >
        <div className="pt-0 px-4 mb-4 font-Nunito text-center">

          <div className="fixed z-50 top-0 inset-x-0 bg-secondary-lightblue-300 pb-4">
            <div className="flex justify-center items-center">
              <img src={Img1} className="w-64 h-24" alt="icon" />
            </div>

            <div className="border border-secondary-darkblue-500 w-full"></div>
            </div>
            <div className="relative mt-28">
            <div className="text-2xl font-bold mt-6">Dine In Menu</div>
          <div className="text-sm text-secondary-gray-500">
            Select your category below
          </div>
          {menuData.map((item, idx) => (
            <Link
              to="/ametrine24/main_menu"
              state={{ detailData: item.itemArray, group:item.group_name }}
              key={idx}
            >
              <div className="m-4 text-3xl bg-menu_category bg-no-repeat bg-cover ">
                <div className="bg-black bg-opacity-30 flex justify-center items-center p-10 text-white font-bold">
                  <div>{item.group_name}</div>
                </div>
              </div>
            </Link>
          ))}
            </div>
          
          {/* <div className="m-2 text-4xl  bg-menu_category bg-no-repeat bg-cover ">
          <div className="bg-black bg-opacity-30 flex justify-center items-center p-10 text-white font-bold">
            <div>Salad</div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default DineInMenuPage;
