import React from "react";
import loadingSpinner from "../assets/images/loader_2.gif";
// import loadingSpinner from "../assets/images/loader.gif";

const Loader = () => {
  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 600,
        backgroundColor: "#F1F9FE",
      }}
    >
      <img src={loadingSpinner} alt="loader" width="150px" height="150px" />
    </div>
  );
};

export default Loader;
